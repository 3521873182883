<template>
  <div class="container" ref="container">
    <ul class="artist-list" v-if="!init || artistData.length">
      <template v-for="(row, key) in artistData">
        <a-item :key="key" :row="row" />
      </template>
    </ul>
    <blank-list v-else text="찜한 전문가가 없습니다" />
  </div>
</template>

<script>
import AItem from '@/components/artist/AItem'
import BlankList from '@/components/blank/BlankList'

export default {
  name: 'ClientFavArtist',
  components: {
    AItem,
    BlankList,
  },
  data() {
    return {
      init: false,
      artistData: [],
      scroll: {
        lastY: 0,
      },
      history: {
        exit: false,
      },
    }
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/client/fav') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
          .then(() => {
            this.getArtistData()
          })
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      } else {
        Vue.getArtistData()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.scroll.lastY = this.$refs.container.scrollTop
        next()
      }
    })
  },
  methods: {
    getArtistData() {
      const req = {
        method: 'get',
        url: '/client/fav',
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.artistData = data.response.artistData || []
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
